
export const color = {
    black: '#000',
    white: '#fff',
    whiteLight: '#aaa',
    whitePointOne: 'rgba(255, 255, 255, 0.1)',
    primaryLight: '#718EFF',
    primary: '#2616FF',
    primaryDark: '#093234',
    secondaryLight: '#838798',
    secondary: '#272931',
    secondaryDark: '#121317',
    accent: '#FF85FC',
    link: '#d1d155',
    buttonGradient: '#7D73FF',
    search: '#7B8198',
    searchFocus: '#C1C3CB',
    blurEffect: `rgba(18,19,22,.8)`,
    lightBlurEffect: `rgba(18,19,22,.01)`,
    lightgray: 'rgba(0,0,0,0.03)',
    lightgray1: 'rgba(0,0,0,0.5)',
    lightgray2: 'rgba(0,0,0,0.05)',
    lightgray3: '#F6F7F8',
    lightgray4: 'rgba(0,0,0,0.1)',
    lightgray5: 'rgba(0,0,0,0.2)',
    lightBlue1: 'rgba(20,200,230,0.05)',
    lightBlue2: '#F4FDFD',
    gradient: 'linear-gradient(to bottom, #fff, #D6ECFA 30%, #CCFAFF 50%, #D6ECFA 70%, #fff)',
    mainGradient: 'linear-gradient(to right, #AABBFF, #A8A2FF 90%)',
    slantedGradient: 'linear-gradient(45deg, #fff, #D6ECFA 30%, #CCFAFF 50%, #D6ECFA 70%, #fff)',
    boxShadow: "0px 3px 11px 0px #e8eafc, 0 3px 3px -2px #b2b2b21a, 0 1px 8px 0 #9a9a9a1a",
    boxShadowBlack: "0px 3px 11px 0px #999, 0 3px 3px -2px #b2b2b21a, 0 1px 8px 0 #999",
    boxShadowZero: "0px 0px 0px 0px #999, 0 0px 0px 0px #b2b2b21a, 0 0px 1px 0 #999",
    background: "linear-gradient(to bottom,rgba(0,0,0,0) 0,rgba(0,0,0,0.5) 50%,rgba(0,0,0,0.7) 100%)",
    red: 'red'
}

// export const color = {
//     black: '#000',
//     white: '#fff',
//     whiteLight: '#aaa',
//     primaryLight: '#A9ECEF',
//     primary: '#27CED7',
//     primaryDark: '#093234',
//     secondaryLight: '#B7BFC9',
//     secondary: '#515D6B',
//     secondaryDark: '#1B1E23',
//     lightgray: 'rgba(0,0,0,0.03)',
//     lightgray1: 'rgba(0,0,0,0.5)',
//     lightgray2: 'rgba(0,0,0,0.05)',
//     lightgray3: '#F6F7F8',
//     lightgray4: 'rgba(0,0,0,0.1)',
//     lightBlue1: 'rgba(20,200,230,0.05)',
//     lightBlue2: '#F4FDFD',
//     gradient: 'linear-gradient(to bottom, #fff, #D6ECFA 30%, #CCFAFF 50%, #D6ECFA 70%, #fff)',
//     slantedGradient: 'linear-gradient(45deg, #fff, #D6ECFA 30%, #CCFAFF 50%, #D6ECFA 70%, #fff)',
//     boxShadow: "0px 3px 11px 0px #e8eafc, 0 3px 3px -2px #b2b2b21a, 0 1px 8px 0 #9a9a9a1a",
//     boxShadowBlack: "0px 3px 11px 0px #999, 0 3px 3px -2px #b2b2b21a, 0 1px 8px 0 #999",
//     boxShadowZero: "0px 0px 0px 0px #999, 0 0px 0px 0px #b2b2b21a, 0 0px 1px 0 #999",
//     background: "linear-gradient(to bottom,rgba(0,0,0,0) 0,rgba(0,0,0,0.5) 50%,rgba(0,0,0,0.7) 100%)",
//     red: 'red'
// }

